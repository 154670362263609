<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Processing Records' Statistics</b-card-title>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <!-- radio -->
        <b-form-radio-group
          v-model="visiblePeriod"
        >
          <b-form-radio
            value="yearly"
          >
            Yearly
          </b-form-radio>
          <b-form-radio
            value="monthly"
          >
            Monthly
          </b-form-radio>
          <b-form-radio
            value="daily"
          >
            Daily
          </b-form-radio>
        </b-form-radio-group>
      </div>
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-bar-chart
        :height="280"
        :data="chartData"
        :options="chartjsData.processRecordChart.options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BFormGroup, BFormRadio, BFormRadioGroup
} from 'bootstrap-vue'
// import flatPickr from 'vue-flatpickr-component'

import { $themeColors } from '@themeConfig'
import ChartjsComponentBarChart from './chartjs/ChartjsComponentBarChart.vue'
import chartjsData from './chartjs/chartjsData'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,

    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    // flatPickr,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      chartjsData,
      visiblePeriod: 'daily',
      chartData: {
        labels: ['7/12', '8/12', '9/12', '10/12', '11/12', '12/12', '13/12', '14/12', '15/12', '16/12', '17/12'],
        datasets: [
          {
            data: [275, 90, 190, 205, 125, 85, 55, 87, 127, 150, 330, 280, 30],
            backgroundColor:  $themeColors.primary,
            borderColor: 'transparent',
          },
        ],
      },
    }
  },
  setup() {
    return {
      
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
