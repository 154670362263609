import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import api from '@/api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

/**
 * 
 * @param {*} param0 : submission filter
 * @returns 
 */
export default function useSubmissionsList({assignee, client}) {
  // Use toast
  const toast = useToast()

  const refSubmissionListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: '_id', label: 'id', sortable: false },
    { key: 'slug', sortable: true },
    { key: 'client', sortable: true },
    { key: 'assignee', sortable: true },
    { key: 'amount', sortable: true, formatter: val => `$${val}` },
    { key: 'status', sortable: true },
    { key: 'createdAt', sortable: false },
    { key: 'actions' },
  ]

  const statusOptions = {
    Draft: 'draft',
    'Checked out': 'checked-out',
    'Accepted': 'accepted',
    'Approved': 'approved',
    Submitted: 'submitted',
    Completed: 'completed',
    Rejected: 'rejected',
  }

  const perPage = ref(10)
  const totalSubmissions = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('createdAt')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const assigneeFilter = ref(assignee)
  const clientFilter = ref(client)

  const dataMeta = computed(() => {
    const localItemsCount = refSubmissionListTable.value ? refSubmissionListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSubmissions.value,
    }
  })

  const refetchData = () => {
    refSubmissionListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, assigneeFilter, clientFilter], () => {
    refetchData()
  })

  const fetchSubmissions = (ctx, callback) => {
    api.submissions.fetchSubmissions({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      status: statusOptions[statusFilter.value],
      assignee: assigneeFilter.value,
      client: clientFilter.value,
    })
    .then(response => {
      const { submissions, total } = response.data

      callback(submissions)
      totalSubmissions.value = total
    })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: "Error fetching submission' list",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }

  const childrenCount = (item) => {
    return item.children.split(',').length
  }

  const childrenList = (item) => {
    return item.children.split(',')
  }

  return {
    fetchSubmissions,
    tableColumns,
    perPage,
    currentPage,
    totalSubmissions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSubmissionListTable,

    statusOptions: Object.keys(statusOptions),
    statusFilter,
    assigneeFilter,
    clientFilter,

    childrenCount,
    childrenList,

    refetchData,
  }
}
