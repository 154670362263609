<template>
  <b-card>
    <b-card-title>
      Processing Records
    </b-card-title>

    <div v-if="!totalRecords" id="no-content" class="d-flex justify-content-center align-items-center">
      <span class="text-muted">No History!</span>
    </div>
    <!-- timeline -->
    <app-timeline>
      <app-timeline-item 
        v-for="record in records"
        :key="record._id"
        :variant="resolveProcessRecordVariant(record.action)"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-sm-0">
          <h6>
            {{capitalize(record.action)}} / 
            <b-link :to="requestDetailLink(record)">
              {{record.request.slug}}
            </b-link>
            (${{record.request.amount}})
          </h6>
          <small class="text-muted">{{new Date(record.createdAt).toLocaleString()}}</small>
        </div>
        <p class="mb-0">{{record.message}}</p>
      </app-timeline-item>
    </app-timeline>

    <!-- pagination -->
    <div class="mt-2">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end">
          <b-pagination
            v-if="totalRecords"
            v-model="currentPage"
            :total-rows="totalRecords"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import api from '@/api'
import { ref, watch, computed, onMounted } from '@vue/composition-api'
import { BRow, BCol, BCard, BCardTitle, BAvatar, BLink, BPagination } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {avatarText} from '@core/utils/filter'
import capitalize from 'lodash.capitalize'
import { resolveProcessRecordVariant } from '@/common/Variants.js'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BLink,
    BCard,
    BCardTitle,
    BPagination,

    AppTimeline,
    AppTimelineItem,
  },
  props: {
    user: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const records = ref([])
    const perPage = ref(5)
    const totalRecords = ref(0)
    const currentPage = ref(1)
    const dataMeta = computed(() => {
      const localItemsCount = records.value.length
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRecords.value,
      }
    })

    const fetchProcessRecords = () => {
      api.processRecords.fetchRecords({
        user: props.user,
        perPage: perPage.value,
        page: currentPage.value
      })
      .then(response => {
        totalRecords.value = response.data.total
        records.value = response.data.records
      })
      .catch(error => reject(error))
    }

    watch([perPage, currentPage], () => {
      fetchProcessRecords()
    })
    onMounted(() => {
      fetchProcessRecords()
    })
    const requestDetailLink = (record) => {
      return {
        name: record.request.type === 'Submission' ? 'submission-detail' : 'renewal-detail',
        params: {id: record.request._id}
      }
    }

    return {
      records,
      totalRecords,
      perPage,
      currentPage,

      dataMeta,
      
      resolveProcessRecordVariant,
      requestDetailLink,
      capitalize,
      avatarText
    }
  }
}
</script>

<style lang="scss" scoped>
#no-content {
  height: 240px;
}
</style>
