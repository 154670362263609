<template>
  <b-card>
    <b-card-title>
      {{title}}
    </b-card-title>
    <b-table
      ref="refSubmissionListTable"
      :items="fetchSubmissions"
      responsive
      :fields="tableColumns"
      primary-key="_id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >

      <!-- Column: Id -->
      <template #cell(_id)="data">
        {{ data.value.slice(-4) }}
      </template>

      <template #cell(slug)="data">
        <b-link
          :to="{ name: 'submission-detail', params: { id: data.item._id }}"
        >
          {{ data.item.slug }}
        </b-link>
        <b-badge
          v-if="data.item.children.length > 0"
          :id="`submission-row-${data.item._id}-children`"
          pill
          variant="info"
        >
          +{{ childrenCount(data.item) }}
        </b-badge>
        <b-tooltip
          v-if="data.item.children.length > 0"
          :target="`submission-row-${data.item._id}-children`"
          placement="bottom"
          offset="10"
        >
          <ul class="list-unstyled m-0">
            <li
              v-for="(item, id) in childrenList(data.item)"
              :key="id"
              class="mb-0"
            >
              {{ item }}
            </li>
          </ul>
        </b-tooltip>
      </template>

      <!-- Column: Client -->
      <template #cell(client)="data">
        {{ data.item.client.email }}
        <b-link
          :to="{ name: 'user-detail', params: { id: data.item.client._id }}"
          target="_blank"
        >
          <feather-icon
            icon="LinkIcon"
          />
        </b-link>
      </template>

      <!-- Column: Assignee -->
      <template #cell(assignee)="data">
        {{ data.item.assignee.email }}
        <b-link
          :to="{ name: 'user-detail', params: { id: data.item.assignee._id }}"
          target="_blank"
          v-if="!!data.item.assignee._id"
        >
          <feather-icon
            icon="LinkIcon"
          />
        </b-link>
      </template>

      <!-- Column: createdAt -->
      <template #cell(createdAt)="data">
        {{ new Date(data.item.createdAt).toLocaleString() }}
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="`light-${resolveSubmissionStatusVariant(data.item.status)}`"
        >
          {{ capitalize(data.item.status) }}
        </b-badge>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalSubmissions"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BRow, BCol, BFormInput, BTable, BMedia, BAvatar, BLink,
  BBadge, BPagination, BTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import capitalize from 'lodash.capitalize'
import useSubmissionList from '../../submission/useSubmissionList.js'
import { resolveSubmissionStatusVariant } from '@/common/Variants.js'

export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BTooltip,

    vSelect,
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    title() {
      return this.user.role === 'admin' ? 'Assigned submissions' : 'Submitted submissions'
    }
  },
  setup(props) {
    // Table Handlers
    const tableColumns = [
      { key: '_id', label: 'id', sortable: false },
      { key: 'slug', sortable: true },
      { key: props.user.role === 'admin' ? 'client' : 'assignee', sortable: true },
      { key: 'amount', sortable: true, formatter: val => `$${val}` },
      { key: 'status', sortable: true },
      { key: 'createdAt', sortable: false },
    ]

    const {
      fetchSubmissions,
      perPage,
      currentPage,
      totalSubmissions,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refSubmissionListTable,
      childrenCount,
      childrenList,
    } = useSubmissionList(
      {[props.user.role === 'admin' ? 'assignee' : 'client']: props.user._id}
    )


    return {
      tableColumns,
      fetchSubmissions,
      perPage,
      currentPage,
      totalSubmissions,
      dataMeta,
      sortBy,
      isSortDirDesc,
      refSubmissionListTable,
      childrenCount,
      childrenList,

      capitalize,

      resolveSubmissionStatusVariant
    }
  }
}
</script>
